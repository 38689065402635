@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Titan+One&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


/* h1,h2,h3,h4,h5,h6{
  font-family: 'Rajdhani', sans-serif !important;
  font-weight: 700;
}

body{
  font-family: 'Rajdhani', sans-serif !important;
  font-weight: 400;
} */
.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

@media (max-width: 800px) {
  .my-masonry-grid {
    margin-left: -15px; /* gutter size offset */
  }
  .my-masonry-grid_column {
    padding-left: 15px; /* gutter size offset */
  }
  .my-masonry-grid_column > div {
    margin-bottom: 15px; /* space between items */
  }
}